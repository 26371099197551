import React from "react"
import { Sources } from "@elements/index"
import { rules } from "@styles/mq"
import { Divider, Grid, Responsive } from "semantic-ui-react"
import theme from "@styles/theme"
import { GridColumnFull, PlateDesignContainer, PlateDesignImage, PlateDesignDataContainer,  PriceBlock, Body, PlateDesignHeader, LeftColumn, RightColumn, ContainerRow, ContainerRowNoBottom, Subtitle, LeftColumnWrapper, RightColumnWrapper, TopText, ImageWrapper, FeatureProductRow } from "./FeatureProductImageGrid.styles"
import KpButton from "@elements/KpButton/KpButton"
import { product } from "@components/HomepageBlack/Types"

type props = {
    elements: product[],
    apiUrl: string
}

const RenderPlatDesignItem = (design: product, apiUrl: string, isMobile=false) => {
    const imageUrl = isMobile ? design.mobile_image_url : design.desktop_image_url
    return (
        <GridColumnFull>
            <Grid stackable columns={2} stretched>
                <Grid.Row>
                    {/* <LeftColumnWrapper width={10} */}
                    <LeftColumnWrapper mobile={16} tablet={10} computer={10} >
                        <PlateDesignContainer>
                            <a id={design.gtm_unique_id} href={design.view_more_url}>
                                <PlateDesignImage
                                    className="banner"
                                    description={design.description}
                                    fallback={`${imageUrl}?w=768&auto=format 1x, ${imageUrl}?w=1536&auto=format 2x`}
                                    lowSrc={`${imageUrl}?w=100&auto=format`}
                                    sources={Sources(imageUrl, rules, theme.imageBreakpoints)}
                                />
                            </a>
                        </PlateDesignContainer>
                    </LeftColumnWrapper>
                    {/* <RightColumnWrapper width={6}> */}
                    <RightColumnWrapper mobile={16} tablet={6} computer={6}>
                        <PlateDesignDataContainer isMobile={isMobile}>
                            <Responsive minWidth={theme.responsive.desktop.minWidth}>
                                <Grid columns={1}>
                                    <ContainerRowNoBottom>
                                        <TopText>PLATE DESIGN</TopText>
                                        <LeftColumn width={16}><PlateDesignHeader>{design.description}</PlateDesignHeader></LeftColumn>                                        
                                    </ContainerRowNoBottom>
                                    <ContainerRow>
                                        <Subtitle>{design.subtitle}</Subtitle>
                                    </ContainerRow>
                                    <Divider className="feature-divider" clearing />
                                </Grid>  
                            </Responsive>
                            <Responsive minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.tablet.maxWidth}>
                                <Grid columns={1}>
                                    <ContainerRowNoBottom>
                                        <TopText>PLATE DESIGN</TopText>
                                        <LeftColumn width={16}><PlateDesignHeader>{design.description}</PlateDesignHeader></LeftColumn>                                        
                                    </ContainerRowNoBottom>
                                    <ContainerRow>
                                        <Subtitle>{design.subtitle}</Subtitle>
                                    </ContainerRow>
                                    <Divider clearing  className="feature-divider"/>
                                </Grid>  
                            </Responsive>
                            <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                <Grid columns={2}>
                                    <ContainerRowNoBottom>
                                        <LeftColumn width={10}><PlateDesignHeader>{design.description}</PlateDesignHeader></LeftColumn>
                                        <RightColumn width={6}><img src={ apiUrl+design.plate_image_url}/></RightColumn>
                                    </ContainerRowNoBottom>
                                    <ContainerRow>
                                        <Subtitle>{design.subtitle}</Subtitle>
                                    </ContainerRow>
                                </Grid>   
                                <Divider clearing  className="feature-divider"/>
                            </Responsive>   
                            <PriceBlock>
                                <label>{design.new_from_label}</label>
                                <span>{design.new_from_price}</span>
                                <label style={{paddingLeft:'8px'}}>{design.restyle_from_label}</label>
                                <span>{design.resytle_from_price}</span>
                            </PriceBlock>
                            <Divider clearing  className="feature-divider2"/>
                            <div>
                                <KpButton id={'create_button_' + design.gtm_unique_id} buttonType='primary' link={design.create_button_url} color={theme.brand.colors.blue}>{design.create_button_text}</KpButton>
                            </div>
                            <Responsive minWidth={theme.responsive.desktop.minWidth}>
                                <Grid>
                                    <Grid.Column width={16}><ImageWrapper src={ apiUrl+design.plate_image_url}/></Grid.Column>
                                </Grid>  
                            </Responsive>
                            <Responsive minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.tablet.maxWidth}>
                                <Grid>
                                    <Grid.Column width={16}><ImageWrapper src={ apiUrl+design.plate_image_url}/></Grid.Column>
                                </Grid> 
                            </Responsive>                
                            {/* <div>
                                
                                <a id={design.gtm_unique_id} href={design.view_more_url}>{design.view_more_label}</a>
                            </div>  */}
                        </PlateDesignDataContainer>
                    </RightColumnWrapper>
                </Grid.Row>
            </Grid>
        </GridColumnFull>
    )
}

const FeatureProductImageGrid = ({ elements, apiUrl }: props) => {
    return (
        <Body>
            <Responsive minWidth={theme.responsive.desktop.minWidth}>
                <Grid columns={1}>
                    <FeatureProductRow centered>
                        {
                            elements.map(item => {
                                return RenderPlatDesignItem(item, apiUrl)
                            })
                        }
                    </FeatureProductRow>
                </Grid>
            </Responsive>

            <Responsive minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.tablet.maxWidth}>
                <Grid columns={1}>
                    <FeatureProductRow centered>
                        {
                            elements.map(item => {
                                return RenderPlatDesignItem(item, apiUrl)
                            })
                        }
                    </FeatureProductRow>

                </Grid>
            </Responsive>
            <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                <Grid columns={1}>
                    <FeatureProductRow centered>
                        {
                            elements.map(item => {
                                return RenderPlatDesignItem(item, apiUrl, true)
                            })
                        }
                    </FeatureProductRow>
                </Grid>
            </Responsive>
        </Body>
    )
}

export default FeatureProductImageGrid