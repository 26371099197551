import React from 'react'
import styled from "@emotion/styled";
import { H1, H4, Paragraph2 } from "@styles/Global.styles";
import { GridColumn } from "semantic-ui-react";
import mq from '@styles/mq'
import { KenticoImage, KpButton, LazyLoadImage } from '@elements/index';
import theme from '@styles/theme';

export const PlateStorySliderItemContainer = styled.div`
    position: relative;
    display: block;
    border-radius: 4px;
    width: 100%;
`

export const PlateStorySliderItemBackgroundImage = styled(({...others})=><KenticoImage {...others} />)`
    width: 100%;
    border-radius: 4px;
`

export const PlateStorySliderItemContent = styled.div`
    position: absolute;
    text-align: center;
    width:100%;
    border-radius: 4px;
    left: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: transparent linear-gradient(180deg, #00000000 0%, #00000099 100%) 0% 0% no-repeat padding-box;

    ${
        mq({
            padding: ['30px','50px', '50px']
        })
    }
`

export const PlateStorySliderItemHeadingSmall = styled.span`
    color: white;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 0.75;
`

export const PlateStorySliderItemHeading = styled(H1)`
    &&&& {
        margin-top: 5px;
    }
`

export const PlateStorySliderItemButton = styled(({...others})=><KpButton {...others} />)`
    border: 3px solid white !important;
    color: white !important;
    background-color: transparent !important;

    &:hover {
        background-color: transparent !important;
    }
`

export const PlateStorySliderArrowContainer = styled(({arrowSide, ...others})=><div {...others} />)`
    &&&& {
        ${props => props.arrowSide}: -90px;
        border: 3px solid ${theme.brand.colors.blue} !important;
        width: 60px !important;;
        height: 60px !important;;
        border-radius: 60px; 
        font-size: 0; 
        line-height: 0;
        position: absolute;
        top: 43%;
        width: 20px;
        height: 20px;
        padding: 14px ${props => props.arrowSide === 'left' ? '10px' : '14px'};
        cursor: pointer;
        border: none; 

        i {
            color: ${theme.brand.colors.blue} !important;
        }
    } 
`