import React from "react"
import { Sources } from "@elements/index"
import { rules } from "@styles/mq"
import { CallToActionCardListSegment, CallToActionCardTitle, CallToActionCardListContainer, CallToActionImageCardContainer, CallToActionImageCardButton, CallToActionCardImage, CallToActionImageCardContentContainer } from './CallToActionCardList.styles'
import { Caption } from "@styles/Global.styles"
import { ButtonType } from '@utils/KontentTypes'
import theme from "@styles/theme"

type CallToActionImageCardListProps = {
  cards: CallToActionImageCard[],
  backgroundColor: string | string[],
  cardBackgroundColor: string | string[],
  alternateButtonLayout: boolean
}

type CallToActionImageCard = {
  backgroundImageUrl: string
  title: string
  subTitle: string
  button: ButtonType
  textColor: string | string[]
}

export type CardGridColumn = 3 | 4;


const CallToActionImageCardList = ({ cards, backgroundColor }: CallToActionImageCardListProps) => {
  return (<CallToActionCardListSegment backgroundColor={backgroundColor}>
    <CallToActionCardListContainer>
      {
        cards.map((card, i) => {
          const { backgroundImageUrl, title, subTitle, button } = card;
          return <CallToActionImageCardContainer key={i} textColor={card.textColor}>
            <CallToActionCardImage
              className="banner"
              description={backgroundImageUrl}
              fallback={`${backgroundImageUrl}?w=768&auto=format 1x, ${backgroundImageUrl}?w=1536&auto=format 2x`}
              lowSrc={`${backgroundImageUrl}?w=100&auto=format`}
              sources={Sources(backgroundImageUrl, rules, theme.imageBreakpoints)}
            />
            <CallToActionImageCardContentContainer>
              <div>
                <CallToActionCardTitle>{title}</CallToActionCardTitle>
                <Caption>{subTitle}</Caption>
              </div>
              <div>
                <CallToActionImageCardButton id={`cta_button_${i}`} fullWidth='mobile tablet' buttonType={button.type} color={button.theme} link={button.url}>
                  {button.text}
                </CallToActionImageCardButton>
              </div>
            </CallToActionImageCardContentContainer>
          </CallToActionImageCardContainer>
        })
      }
    </CallToActionCardListContainer>
  </CallToActionCardListSegment>
  )
}

CallToActionImageCardList.defaultProps = {
  backgroundColor: "white",
  cardBackgroundColor: "white"
}

export default CallToActionImageCardList