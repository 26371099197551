import { PlateStorySliderItemsProps } from "@models/PlateStorySlider/PlateStorySlider.models"
import { mapToButton } from "@utils/mapper"

export const mapToPlateStorySliderItems = (data: any): PlateStorySliderItemsProps => {
    return {
        items: data.map((data: any) => {
            const plateStorySliderItem = data.elements;
            return {
                backgroundImage: {
                    desktop: {
                        url: plateStorySliderItem.background_image.value[0].url,
                        description: plateStorySliderItem.background_image.value[0].description,
                    },
                    mobile: {
                        url: plateStorySliderItem.background_image___mobile.value[0].url,
                        description: plateStorySliderItem.background_image___mobile.value[0].description,
                    }
                },
                heading: plateStorySliderItem.title.value,
                button: mapToButton(plateStorySliderItem)
            }
        })
    }
}
