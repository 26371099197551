import React, { createRef, useEffect } from "react"
import ReactSlick from "react-slick"
import { HeaderTitle, SliderContainer } from "./PlateDesignSlider.styles"
import { PlateDesignStyleContainer } from "@components/HomepagePopularPlatesCarousel/PlateDesignStyleContainer"

let firstClientX, clientX

const preventTouch = e => {
  const minValue = 5 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    //e.preventDefault();
    e.returnValue = false

    return false
  }
}

const touchStart = e => {
  firstClientX = e.touches[0].clientX
}

const PlateDesignSlider = props => {
  let containerRef = createRef()

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 7,
    lazyLoad: 'progressive',
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 2570,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false
        }
      },
      {
        breakpoint: 539,
        settings: {
          centerMode: true,          
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false
        }
      }
    ]
  };

  useEffect(() => {
    let currentContainerRef
    if (containerRef.current) {
      currentContainerRef = containerRef.current
      currentContainerRef.addEventListener("touchstart", touchStart)
      currentContainerRef.addEventListener("touchmove", preventTouch, {
        passive: false,
      })
    }

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener("touchstart", touchStart)
        currentContainerRef.removeEventListener("touchmove", preventTouch, {
          passive: false,
        })
      }
    }
  })

  return (
    <SliderContainer ref={containerRef}>
      <HeaderTitle>{props.title}</HeaderTitle>
      <ReactSlick {...settings}>
        {props.plateDesigns.map((data, index) => {
          return (
            <PlateDesignStyleContainer
              data={data}
            />
          )
        })}
      </ReactSlick>
    </SliderContainer>
  )
}

export default PlateDesignSlider
