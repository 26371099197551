import React from 'react'
import styled from '@emotion/styled';
import mq from '@styles/mq'
import {Segment, Image, GridRow, Grid, GridColumn} from 'semantic-ui-react'
import {H3, H5} from '@styles/Global.styles';
import { KenticoImage, KpButton, LazyLoadImage } from '@elements/index';
import { mapToPromotionPopups } from '@mappers/PromotionPopup/mapper';
import theme from '@styles/theme';
import facepaint from 'facepaint';

const breakpoints = facepaint([
  '@media(min-width: 420px)',
  '@media(min-width: 768px)',
  '@media(min-width: 1024px)',
  '@media(min-width: 1440px)',
])

export const CallToActionCardListSegment = styled(({backgroundColor, ...rest})=> <Segment {...rest} />)`
  background-color: ${props => props.backgroundColor} !important;
  margin: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
`
export const CallToActionCardListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpoints({
    flexDirection: ['column;','column;','row;','row;','row;']
  })}
`

export const CallToActionCardListRow = styled(({verticalOffset, ...rest})=><GridRow {...rest} />)`
    ${props => mq({
      marginTop :[props.verticalOffset.mobile + ' !important', props.verticalOffset.tablet + ' !important', props.verticalOffset.desktop + ' !important', props.verticalOffset.wideScreen + ' !important']
    })};
`

export const CallToActionCardTitle = styled(H3)`
  ${mq({
    margin:['5px 0;','10px 0;','10px 0']
  })}
  font-weight: bold !important;
`

export const CallToActionImageCardContainer = styled(({backgroundImageUrl, textColor, ...rest})=><div {...rest} />)`
  border-radius: 4px;
  box-shadow: 0px 6px 16px #0000001F;
  position: relative;
  color: ${props => props.textColor};
  ${breakpoints({
    margin:['10px 0px;','10px 0;','10px;','0 15px;','0 15px;'],
    maxWidth: ['auto;','auto;','240px;','302px;','435px;'],
    width: ['auto;','auto;','100%;','100%;','100%;'],
    height: ['86vw;','87vw;','212px;','280px;','405px;']
  })}

  :first-child {
    margin-left: 0 !important;
  }

  :last-child {
    margin-right: 0 !important;
  }
`

export const CallToActionCardButton = styled(({color,hoverColor, ...rest})=><KpButton {...rest} />)`
  margin-top: 15px !important;
  border-color: ${props => props.color} !important;
  color: ${props => props.color} !important;
  background-color: transparent !important;
  &:hover {
    color: ${props => props.hoverColor} !important;
    background-color: ${props => props.color} !important;
  }
`

export const CallToActionCardContainer = styled(({backgroundColor, textColor, ...rest})=><div {...rest} />)`
  background-color: ${props => props.backgroundColor};
  box-shadow: 0px 6px 16px #0000001F;
  border-radius: 12px;
  text-align: left;
  padding: 30px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  color: ${props => props.textColor};
  ${mq({
    padding:['30px','30px','40px']
  })}
`

export const CallToActionImageCardButton = styled(({...rest})=><KpButton {...rest} />)`
  ${mq({
    minWidth:['0 !important;','0 !important;','200px !important;']
  })}
`

export const CallToActionCardImage = styled(({...rest})=><KenticoImage {...rest} />)`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
`

export const CallToActionImageCardContentContainer = styled.div`
  border-radius: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 30px;
  text-align: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;
`

export const CallToActionCardIconContainer = styled(Image)`
  &.image {
    width: 30px;
    height: 30px;
    position: relative;
    margin-bottom: 15px;
    img {
      position: absolute !important;
      bottom: 0;
    }
    ${mq({
      display:['none','none','block']
    })}
  }
` 

export const CallToActionCardListGrid = styled(Grid)`
`