import { HowItWorksProps } from "@models/HowItWorks/HowItWorks.models";

export const mapToHowItWorksItems = (data: any): HowItWorksProps => {
    return {
        title: data.title.value,
        subtitle: data.subtitle.value,
        description: data.description.value,
        learn_more_button_text: data.learn_more_button_text.value,
        learn_more_button_url:  data.learn_more_button_url.value,
        steps: data.steps.linked_items.map((item: any) => {           
            return {
                title: item.elements.title.value,
                content: item.elements.content.value,
            }
        }),
        step_icons: data.step_icons.value
    }
}
