import React from "react"
import { Sources } from "@elements/index"
import { rules } from "@styles/mq"
import { Grid, Responsive } from "semantic-ui-react"
import { H2, H6 } from "@styles/Global.styles"
import { PlateDesignImageGridItem, PlateDesignImageGridProps } from "@models/PlateDesignImageGrid/PlateDesignImageGrid.models"
import theme from "@styles/theme"
import { PlateDesignContainer, PlateDesignHeading, PlateDesignImage, PlateDesignImageButtonContainer, PlateDesignImageContentContainer, PlateDesignImageGridColumn, PlateDesignImageGridHeadingContainer, PlateDesignImageGridSubHeading, PlateDesignImagePrice } from "./PlateDesignImageGrid.styles"
import KpButton from "@elements/KpButton/KpButton"

const RenderPlatDesignItem = (design: PlateDesignImageGridItem) => {
    return (
        <PlateDesignContainer hideOnMobile={design.hideOnMobile}>
            <PlateDesignImage
                className="banner"
                description={design.backgroundImage.description}
                fallback={`${design.backgroundImage.url}?w=768&auto=format 1x, ${design.backgroundImage.url}?w=1536&auto=format 2x`}
                lowSrc={`${design.backgroundImage.url}?w=100&auto=format`}
                sources={Sources(design.backgroundImage.url, rules, theme.imageBreakpoints)}
            />
            <PlateDesignImageContentContainer>
                <PlateDesignHeading color={theme.brand.colors.white}>
                    {design.heading}
                </PlateDesignHeading>
                <PlateDesignImagePrice>
                    <span>From</span>
                    <H6 color={theme.brand.colors.white}>
                        ${design.price}
                    </H6>
                </PlateDesignImagePrice>
                <KpButton textColor={theme.brand.colors.black} id={`featured_design_${design.heading}`} buttonType={design.button.type} color={design.button.theme} link={design.button.url}>{design.button.text}</KpButton>
            </PlateDesignImageContentContainer>
        </PlateDesignContainer>
    )
}

const PlateDesignImageGrid = ({ designs, heading, subHeading }: PlateDesignImageGridProps) => {
    const plateDesignCountPerColumnDesktop = designs.length / 3;
    const plateDesignCountPerColumnMobile = designs.length / 2;
    return (
        <div>
            <PlateDesignImageGridHeadingContainer>
                <H2>{heading}</H2>
                {
                    subHeading &&
                    <PlateDesignImageGridSubHeading color={theme.brand.colors.darkGrey}>{subHeading}</PlateDesignImageGridSubHeading>
                }
            </PlateDesignImageGridHeadingContainer>
            <Responsive minWidth={theme.responsive.desktop.minWidth}>
                <Grid columns={3}>
                    <Grid.Row centered>
                        <PlateDesignImageGridColumn>
                            {
                                designs.map((design: PlateDesignImageGridItem, index: any) => {
                                    if (index < plateDesignCountPerColumnDesktop) {
                                        return RenderPlatDesignItem(design)
                                    }
                                })
                            }
                        </PlateDesignImageGridColumn>
                        <PlateDesignImageGridColumn textAlign='center'>
                            {
                                designs.map((design: PlateDesignImageGridItem, index: any) => {
                                    if (index >= plateDesignCountPerColumnDesktop && index < plateDesignCountPerColumnDesktop * 2) {
                                        return RenderPlatDesignItem(design)
                                    }
                                })
                            }
                            <PlateDesignImageButtonContainer>
                                <KpButton id='browse_styles' fullWidth='mobile tablet' buttonType='primary' color={theme.brand.colors.blue} link='/plate-designs/'>BROWSE ALL STYLES</KpButton>
                            </PlateDesignImageButtonContainer>
                        </PlateDesignImageGridColumn>
                        <PlateDesignImageGridColumn>
                            {
                                designs.map((design: PlateDesignImageGridItem, index: any) => {
                                    if (index >= plateDesignCountPerColumnDesktop * 2) {
                                        return RenderPlatDesignItem(design)
                                    }
                                })
                            }
                        </PlateDesignImageGridColumn>
                    </Grid.Row>
                </Grid>
            </Responsive>

            <Responsive minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.tablet.maxWidth}>
                <Grid columns={16}>
                    <Grid.Row centered>
                        <PlateDesignImageGridColumn width={8}>
                            {
                                designs.map((design: PlateDesignImageGridItem, index: any) => {
                                    if (index < plateDesignCountPerColumnMobile) {
                                        return RenderPlatDesignItem(design)
                                    }
                                })
                            }
                        </PlateDesignImageGridColumn>
                        <PlateDesignImageGridColumn width={8}>
                            {
                                designs.map((design: PlateDesignImageGridItem, index: any) => {
                                    if (index >= plateDesignCountPerColumnMobile) {
                                        return RenderPlatDesignItem(design)
                                    }
                                })
                            }
                        </PlateDesignImageGridColumn>
                    </Grid.Row>
                    <PlateDesignImageButtonContainer>
                        <KpButton id='browse_styles' fullWidth='mobile' buttonType='primary' color={theme.brand.colors.blue} link='/plate-designs/'>BROWSE ALL STYLES</KpButton>
                    </PlateDesignImageButtonContainer>
                </Grid>
            </Responsive>
            <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                <Grid columns={16}>
                    <Grid.Row centered>
                        <PlateDesignImageGridColumn width={16}>
                            {
                                designs.map((design: PlateDesignImageGridItem, index: any) => {
                                    if (index <= 5) {
                                        return RenderPlatDesignItem(design)
                                    }
                                })
                            }
                        </PlateDesignImageGridColumn>
                    </Grid.Row>
                    <PlateDesignImageButtonContainer>
                        <KpButton id='browse_styles' fullWidth='mobile' buttonType='primary' color={theme.brand.colors.blue} link='/plate-designs/'>BROWSE ALL STYLES</KpButton>
                    </PlateDesignImageButtonContainer>
                </Grid>
            </Responsive>
        </div>
    )
}

export default PlateDesignImageGrid