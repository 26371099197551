import React from "react"
import { Icon, Responsive } from "semantic-ui-react"
import { Sources } from "@elements/index"
import { rules } from "@styles/mq"
import { PlateStorySliderItemsProps } from "@models/PlateStorySlider/PlateStorySlider.models"
import Slider from "react-slick"
import { PlateStorySliderArrowContainer, PlateStorySliderItemBackgroundImage, PlateStorySliderItemButton, PlateStorySliderItemContainer, PlateStorySliderItemContent, PlateStorySliderItemHeading, PlateStorySliderItemHeadingSmall } from "./PlateStorySlider.styles"
import theme from "@styles/theme"

    type ArrowProps = {
        onClick?: any
    }
    const PrevArrow = (props:ArrowProps) =>{
        const { onClick } = props;
        return (
            <PlateStorySliderArrowContainer arrowSide='left' onClick={onClick}>
                <Icon size='huge' name="chevron left" />
            </PlateStorySliderArrowContainer>
        );
    }
    const NextArrow = (props:ArrowProps) =>{
        const { onClick } = props;
        return (
            <PlateStorySliderArrowContainer arrowSide='right' onClick={onClick}>
                <Icon size='huge' name="chevron right"/>
            </PlateStorySliderArrowContainer>
        );
    }
    const lazyLoadType: "progressive" | "ondemand" | undefined = "ondemand";

    const renderPlateStorySlider = (isMobile: boolean, plateStorySliderProps: any) => {
    
        const desktopSettings = {
            dots: true,
            arrows: true,
            lazyLoad: lazyLoadType,
            infinite: true,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };

        const mobileSettings = {
            arrows: false,
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll:1,
            lazyLoad: lazyLoadType,
            autoplay: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };

    return <Slider {...isMobile ? mobileSettings : desktopSettings}>
    {
        plateStorySliderProps.map((plateStorySliderItem: any) => {
            const plateStorySliderImage = isMobile ? plateStorySliderItem.backgroundImage.mobile : plateStorySliderItem.backgroundImage.desktop;
            return (
                <PlateStorySliderItemContainer>
                    <PlateStorySliderItemBackgroundImage
                        className="banner"
                        onLoad={() => window.dispatchEvent(new Event('resize'))}
                        description={plateStorySliderImage.description}
                        fallback={`${plateStorySliderImage.url}?w=768&auto=format 1x, ${plateStorySliderImage.url}?w=1536&auto=format 2x`}
                        lowSrc={`${plateStorySliderImage.url}?w=100&auto=format`}
                        sources={Sources(plateStorySliderImage.url, rules, theme.imageBreakpoints)}
                    />
                    <PlateStorySliderItemContent>
                        <PlateStorySliderItemHeadingSmall color={theme.brand.colors.white}>Plate Story</PlateStorySliderItemHeadingSmall>
                        <PlateStorySliderItemHeading color={theme.brand.colors.white}>{plateStorySliderItem.heading}</PlateStorySliderItemHeading>
                        <PlateStorySliderItemButton id={`plate_story_slider_${plateStorySliderItem.heading}`} link={plateStorySliderItem.button.url} buttonType='secondary'>{plateStorySliderItem.button.text}</PlateStorySliderItemButton>
                    </PlateStorySliderItemContent>
                </PlateStorySliderItemContainer>
            )
        })
    }
    </Slider>
    }

class PlateStorySlider extends React.Component<PlateStorySliderItemsProps,any> {
    constructor(props:PlateStorySliderItemsProps) {
        super(props);
        this.state = {
            width: 0
        }
    }
    componentDidMount() {
        this.handleWindowSizeChange() // Set width
        window.addEventListener('resize', this.handleWindowSizeChange)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange)
    }
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth })
    }
    render() {
        const {width} = this.state;
        return (
            <div>
                <Responsive getWidth={()=>{return width;}} minWidth={Responsive.onlyTablet.minWidth}>
                    {renderPlateStorySlider(false, this.props.items)}
                </Responsive>
                <Responsive getWidth={()=>{return width;}} maxWidth={Responsive.onlyMobile.maxWidth}>
                    {renderPlateStorySlider(true, this.props.items)}
                </Responsive>
            </div>
        )}
}

export default PlateStorySlider