import React from 'react'
import styled from "@emotion/styled";
import { H4, Paragraph2 } from "@styles/Global.styles";
import { GridColumn } from "semantic-ui-react";
import mq from '@styles/mq'
import { KenticoImage } from '@elements/index';

export const PlateDesignImage = styled(({...others})=><KenticoImage {...others} />)`
    position: relative;
    border-radius: 4px;
    width: 100%;
    display: block;
`

export const PlateDesignHeading = styled(H4)`
    margin-bottom: 7px;
`

export const PlateDesignContainer = styled(({hideOnMobile, ...others})=><div {...others} />)`
    position: relative;
    border-radius: 4px;
    width: 100%;
    ${
        mq({
            marginBottom: ['20px','20px','30px']
        })
    }
    ${ props =>
        mq({
            display: [props.hideOnMobile ? 'none' : 'block' ,props.hideOnMobile ? 'none' : 'block','block;']
        })
    }
    :last-child {
        ${
            mq({
                marginBottom: ['20px','20px','0']
            })
        }
    }
`

export const PlateDesignImageButtonContainer = styled.div`
    width: 100%;
    text-align: center;
    ${
        mq({
            marginTop: ['-10px','-10px','-7px','55px']
        })
    }
`

export const PlateDesignImageContentContainer = styled.div`
    position: absolute;
    border-radius: 4px;
    text-align: left;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition:opacity 0.5s;
    background: transparent linear-gradient(180deg, #00000000 0%, #00000080 100%) 0% 0% no-repeat padding-box;

    ${
        mq({
            opacity: ['1','1','0'],
            padding: ['30px','30px','40px','40px']
        })
    }

    &:hover {
        opacity: 1;
    }
`

export const PlateDesignImageGridSubHeading = styled(Paragraph2)`
    ${mq({
        marginBottom: ['20px !important','20px !important','30px !important']
    })}
    color: #707070;
`

export const PlateDesignImageGridColumn = styled(GridColumn)`

`

export const PlateDesignImageGridHeadingContainer = styled.div`
    text-align: center;
    ${mq({
        marginBottom: ['20px','20px','40px']
    })}
`

export const PlateDesignImagePrice = styled.div`
    > h6 {
        display: inline-block;
        margin-left: 5px !important;
        vertical-align: top;
        font-size: 16px !important;
        line-height: 22px !important;
    }
    > span {
        color: white;
        font-size: 12px;
        line-height: 19px;
        font-weight: 100;
        vertical-align: top;
    }
    margin-bottom: 15px;
`