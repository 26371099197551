import { HomepageItemProps } from "@models/Homepage/Homepage.models";

export const mapToHomepageItem = (data: any): HomepageItemProps => {
    return {
        desktop_image_url: data.desktop_image.value[0].url,
        mobile_image_url: data.mobile_image.value[0].url,
        tablet_image_url: data.tablet_image.value[0].url,
        new_plate_link: data.btn___new_plate_link.value,
        new_plate_title: data.btn___new_plate_title.value,
        new_plate_description: data.btn___new_plate_description.value,
        redesign_plate_link: data.btn___redesign_plate_link.value,
        redesign_plate_title: data.btn___redesign_plate_title.value,
        redesign_plate_description: data.btn___redesign_plate_description.value,
        description: data.description.value,
        newTab: data.new.value,
        mobileTextAlignment: data.mobile___text_align.value,
        createPlateUniqueGTMID: data.create_plate_gtm_unique_id.value,
        redesignPlateUniqueGTMID: data.redesign_plate_gtm_unique_id.value,
        giftingText: data.gifting_text.value,
        giftingLink: data.gifting_link.value,
        giftingGTMID: data.gifting_link_gtm_id.value,
        homepageImageLink: data.homepage_image_link.value
    }
}
