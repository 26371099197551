import { KenticoImage } from '@elements/index';
import styled from '@emotion/styled';
import mq from '@styles/mq';
import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';

export const LoadingScreen = styled.div`
    ${mq({
        minHeight: ['unset', '316px', '388px']
    })}
    z-index: 1;
`

export const Block = styled(({ img, isMobile, mobileTextAlignment, ...rest }) => <div {...rest} />)`
    a{
        color: unset;
    }

    z-index: 1;
    position: relative;
    height: ${props => props.isMobile ? '' : ''};
    background-color: #000;
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: ${props => props.isMobile ? props.mobileTextAlignment : 'left'};
    color: #fff;
    ${mq({
        marginTop: ['32px', '32px', '99px']
    })}
    .nav-button {
        margin-top: ${props => props.isMobile ? '' : '30px !important'};
        position: ${props => props.isMobile ? '' : ''};
        bottom: ${props => props.isMobile ? '0px !important' : ''};
        width: ${props => props.isMobile ? '-webkit-fill-available' : 'fit-content'} !important;
    }

    .title_black {
        color: #000;
    }

    .center {
        margin: auto;
        bottom: 0px;
        ${mq({
            position: ['unset', 'absolute', 'absolute'],
            height: ['85%', 'unset', 'unset'],
            right:['unset', 'unset', '0px'],
            marginRight:['auto','auto','15px'],
            left:['unset','unset', '14px'],
        })}
        z-index: 2;
    }

    h1 {
        font-size: ${props => props.isMobile ? '40px' : '70px'};
        font-weight: bold;
        margin: ${props => props.isMobile ? 'auto' : ''};
        margin-top: 20px;
        width: ${props => props.isMobile ? '290px' : '500px'};
        line-height: ${props => props.isMobile ? '42px' : '68px'};
    }

    .new {
        margin: ${props => props.isMobile ? 'auto' : ''};
        background: #fff;
        color: #3dae2b;
        width: 42px;
        padding: 3px;
        text-align: center;
        border: unset;
        border-radius: 4px;
        font-size: 12px;
        margin-top: 15px;
        padding-top: 7px;
    }

    .btn1 {
        cursor: pointer;
        ${mq({
            width: ['auto', '288px', '318px']
        })}
        background: #3dae2b;
        padding: 16px;
        border-radius: 8px;
        position: relative;
        text-align: left;
        margin: auto;
        height: 82px;
        .title {
            font-weight: bold;
            font-size: 24px;
        }
        .description {
            font-size: 14px;
            font-weight: 500;
        }
        svg {
            position: absolute;
            right: 16px;
            top: 24px;
        }
    }

    .btn2 {
        margin-top: 12px;
        background: #fff !important;
        color: #000;
        text-align: left;
        .description {
            color: #707070;
        }
    }

    .btn3{
        background: black;
    }
`

export const HomepageImage = styled(({height, ...others }) => <KenticoImage {...others} />)`
    position: relative;
    width: 100%;
    display: block;
    cursor: pointer;
    height: ${p => p.height ? p.height+'px' : '100%'};
    ${mq({
        maxHeight:['unset','100%','100%'],
        maxWidth:['unset', '100%', '100%']
    })}
}
`
export const ImageContainer = styled(({ isMobile, hasPadding, ...rest }) => <div {...rest} />)`
    width: 100%;    
    overflow:hidden;
    img{
        width: 100%;
    }
    ${mq({
        marginLeft: ['14px', 'unset', 'unset'],
        marginRight:['14px','unset', 'unset'],
        marginBottom:['32px','unset','unset']
    })}  
    padding-left: ${p => p.hasPadding ? '15px' : 'unset' };  
`

export const LinkButton = styled(({ hasText, isMobile, ...rest }) => <div {...rest} />)`
    margin-top: ${props => props.hasText ? '16px' : '0px'};
    line-height: 24px;
    font-size: 18px;
    text-decoration: underline;
    margin-left: ${props => props.isMobile ? '0px' : '16px'};
`
export const MobileHompageWrapper = styled(Grid)`
    width:100% !important;
    
`
export const MobileHomepageRow = styled(Grid.Row)`
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 30px !important;
`
export const HomepageRow =styled(Grid.Row)`
${mq({
    marginTop: ['', '8px', '32px'],
    maxWidth:['', 'unset','unset'],
    marginBottom:['unset','42px','42px']
})}
    margin-left: auto !important;
    margin-right: auto !important;
`
export const ImageRightColumn = styled(Grid.Column)`
    padding-top: 0px !important;
`
export const DesktopSegment = styled(Segment)`
    background-color:black !important;    
`
export const TabletSegment = styled(Segment)`
    background-color:black !important;
    .container{
        width:100%;        
    }
`

export const HomepageGrid = styled(Grid)`
${mq({
    width: ['unset', '100%', 'unset'],
    marginLeft:['unset', 'auto !important', 'unset'],
    marginRight:['unset', 'auto !important', 'unset']
})}
`