import {product} from './Types'


export const mapDataToModel = (data: any) =>{
    var newProducts: product[] = []
    data.product.linked_items.map ((item: any) => {
        var product: product  = {
            description: item.elements.description.value,
            new_from_label: item.elements.new_from_label.value,
            desktop_image_url: item.elements.image.value[0].url,
            mobile_image_url: item.elements.mobile_image.value[0].url,
            new_from_price: item.elements.new_from_price.value,
            order: item.elements.order.value,
            restyle_from_label: item.elements.restyle_from_label.value,
            resytle_from_price: item.elements.resytle_from_price.value,
            view_more_label: item.elements.view_more_label.value,
            view_more_url: item.elements.view_more_url.value,
            gtm_unique_id: item.elements.gtm_unique_id.value,
            subtitle: item.elements.subtitle.value,
            plate_image_url: item.elements.plate_image_url.value,
            create_button_text: item.elements.create_button_text.value,
            create_button_url: item.elements.create_button_url.value,
        }
        newProducts.push(product)
    })
    return newProducts
}