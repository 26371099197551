import {mapToButton} from '@utils/mapper'
import { convertKontentColorToHex } from '@utils/Helpers';
import { CtaCard } from '@models/CTA Cards/CTACard.models';
import { CtaImageCard } from '@models/CTA Cards/CTAImageCard.models';

export const mapToCTACards = (array: CtaCard[]) =>{
    return array.map(item=>mapToCTACard(item));
}

export const mapToCtaImageCards = (array: CtaImageCard[]) =>{
    return array.map(item=>mapToCtaImageCard(item));
}

export const mapToCTACard = (item:CtaCard) =>{
    const {title,subtitle,icon,background_colors,text_colors} = item.elements;
    const button = mapToButton(item.elements);
    return{
        iconUrl: icon.value.length === 0 ? '' : icon.value[0].url,
        title: title.value,
        subTitle: subtitle.value,
        button:button,
        backgroundColor: convertKontentColorToHex(background_colors.value[0].codename),
        textColor: convertKontentColorToHex(text_colors.value[0].codename)
    }
}

export const mapToCtaImageCard = (item:CtaImageCard) =>{
    const {title,subtitle,background_image,text_colors} = item.elements;
    const button = mapToButton(item.elements);
    return{
        backgroundImageUrl: background_image.value.length === 0 ? '' : background_image.value[0].url,
        title: title.value,
        subTitle: subtitle.value,
        button:button,
        textColor: convertKontentColorToHex(text_colors.value[0].codename)
    }
}